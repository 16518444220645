import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest, HttpErrorResponse  } from '@angular/common/http';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { IMember, IClub, ILap, ILapSetUp, IBikeBrand, IRFIDReader, IEventType, IEvent, IPi, ITimeRead, ISerie, IClass, IMemberInCompetition, IStartType, IIntermediateTimeModel, IConfirmedTimeRead, IupdateUrlMember, IPoint, IEventMember, ILapTimeModel, ILapInEvent, } from '../models/interfaceModels'; //IEventLap
import { ConfigService } from './config.service';
//import { IupdateUrlMember } from '../pages/dialogs/member-profile/member-profile.component';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DBMessage } from '../components/message-input-dialog/message-input-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class DbService {

  constructor(private http: HttpClient,
    private config: ConfigService,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar) { }

  configUrl = this.config.configUrl;

  public allClubs: IClub[] | undefined;
  public allClubsPlus: IClub[] | undefined;
  public allBikeBrands: IBikeBrand[] | undefined;
  public allMembers: IMember[] = [];
  public allLaps: ILap[] = [];

  //USED IN NEW CLIENT
  changedMembers = new ReplaySubject<IMember[]>();
  broadCastMembers = this.changedMembers.asObservable();
  changedMembersInEvent = new ReplaySubject<IMember[]>();
  broadCastMemberInEvent = this.changedMembersInEvent.asObservable();

  //Retrieves all members in database including:
  //      clubModel
  //      bikeBrandModel
  getMembers() {
    
    this.http.get<IMember[]>(this.configUrl + 'Member/').subscribe(
      (data: IMember[]) => {
        console.log('Retrieving all ' + data.length + ' membersfrom DB')
        //console.log(data)
        this.allMembers = data;
        this.changedMembers.next(data)
      }
    );
  }
  getMembersByEvent(eventId: number) {
    console.log('Retrieving members by EventId');
    return this.http.get<IMember[]>(this.configUrl + 'Member/ByEvent/' + eventId)
  }
  updateMemberProfileUrl(file: IupdateUrlMember) {   
    //Get original filetype
    var fileType = file.PictureUrl.name.split('.');
    var lastIdx = fileType.lastIndexOf;
    const formData = new FormData();
    formData.append('file', file.PictureUrl, file.svemoId.toString() + '.' + fileType[fileType.length-1])
    this.http.post<boolean>(this.configUrl + 'Member/MemberUploadImage/', formData).subscribe(
      (data: boolean) => {
        if (data) 
          this.getMembers();    
        else
          alert('could not update picture!')
      })
  }
  addMember(member: IMember, file: IupdateUrlMember) {
    this.http.post<IMember>(this.configUrl + 'Member/', member).subscribe(
      (data: IMember) => {
        //this.changedMembers.next(data)
        if (member.pictureUrl == 'exists') {
          this.updateMemberProfileUrl(file);
        }
        else {
          this.getMembers();
        } 
      });
  }
  getMembersByClub(clubModelId: number) {
    console.log('Retrieving members by ClubId');
    this.http.get<IMember[]>(this.configUrl + 'Member/ByClub/' + clubModelId).subscribe(
      (data: IMember[]) => this.changedMembers.next(data)
    );
  }
  updateMember(member: IMember) {
    this.http.put<IMember>(this.configUrl + 'Member/' + member.svemoId, member).subscribe(
      (data: IMember) => {
        //this.changedMembers.next(data)
        this.snackBar.open('Medlem uppdaterad!', member.firstName + ' ' + member.lastName + ' uppdaterades i databasen',
          {
            duration: this.config.snackBarTiming,
            panelClass: "snackBarOk"
          })
        this.getMembers();
      });
  }
  removeMember(member: IMember) {
    this.http.delete<IMember>(this.configUrl + 'Member/' + member.svemoId).subscribe(
      (data: IMember) => {
        //this.changedMembers.next(data)
        this.snackBar.open('Medlem borttagen!', member.firstName + ' ' + member.lastName + ' togs bort permanent från databasen!',
          {
            duration: this.config.snackBarTiming,
            panelClass: "snackBarErr"
          })
        this.getMembers();
      });
  }

  getProfilePic(svemoId: number): Observable<any> {
    console.log('Retrieving picture of members by SvemoId');
    return this.http.get<any>(this.configUrl + 'MemberDetails/' + svemoId)
  }

  // CLUB MOD
  private changedClubs = new ReplaySubject<IClub[]>();
  broadCastClubs = this.changedClubs.asObservable();
  private changedClubsPlus = new ReplaySubject<IClub[]>();
  broadCastClubsPlus = this.changedClubsPlus.asObservable();

  getClubs() {
    console.log('Retrieving Clubs from DB');
    this.http.get<IClub[]>(this.configUrl + 'Club/').subscribe(
      (data: IClub[]) => {
        this.allClubs = data.filter(club => club.clubModelId > 0);
        this.allClubsPlus = data;
        this.changedClubs.next(this.allClubs)
        this.changedClubsPlus.next(this.allClubsPlus)
      });
  }
  updateClubPictureUrl(club:IClub, picFile:any) {
    //Get original filetype

    var fileType = picFile.name.split('.');
    var lastIdx = fileType.lastIndexOf;
    const formData = new FormData();
    formData.append('file', picFile, club.name + '.' + fileType[fileType.length - 1])

    this.http.post<boolean>(this.configUrl + 'Club/ClubUploadImage/', formData).subscribe(
      (data: boolean) => {
        if (data)
          this.getClubs();
        else
          alert('could not update picture!')
      })
  }
  addClub(club: IClub, picFile : any) {
    this.http.post<IClub[]>(this.configUrl + 'Club/', club).subscribe(
      (data: IClub[]) => {
        if (club.pictureUrl == 'exists') {
          this.updateClubPictureUrl(club, picFile)
        }
        else {
          this.changedClubs.next(data)
        }  
    });

  }
  updateClub(club: IClub, picFile: any) {

    this.http.put<IClub[]>(this.configUrl + 'Club/' + club.clubModelId, club).subscribe(
      (data: IClub[]) => { 
        if (club.pictureUrl == 'exists') {
          this.updateClubPictureUrl(club, picFile)
        }
        else {
          this.changedClubs.next(data)
        }
        this.getClubs();
      });
  }
  removeClub(club: IClub) {
    this.http.delete<IClub[]>(this.configUrl + 'Club/' + club.clubModelId).subscribe(
      (data: IClub[]) => this.changedClubs.next(data));
  }
  getClubById(clubID: number) {
    console.log('Retrieving club by clubId')
    return this.http.get<IClub>(this.configUrl + 'Club/' + clubID);
  }
  private changedMessages = new ReplaySubject<DBMessage>();
  broadCastMessages = this.changedMessages.asObservable();
  getMessagesByClub(clubId: number) {
    console.log(clubId)
    return this.http.get<DBMessage[]>(this.configUrl + 'Message/' +clubId + '/4')
  }
  addMessage(message: DBMessage) {
    console.log(message)
    return this.http.post<DBMessage[]>(this.configUrl + 'Message/', message)
      //.subscribe(
      //(data: DBMessage[]) => {

      //})
  }
  removeMessage(messageId: number) {
    return this.http.delete<DBMessage[]>(this.configUrl + 'Message/' + messageId)
      //.subscribe(
      //(data: DBMessage[]) => {
        
      //})
  }

  // LAP MOD
  private changedLaps = new ReplaySubject<ILap[]>();
  broadCastLaps = this.changedLaps.asObservable();
  getLaps(club: IClub) {
    console.log('Retrieving Laps at club by ClubId')
    this.http.get<ILap[]>(this.configUrl + 'Lap/ByClub/' + club.clubModelId).subscribe(
      (data: ILap[]) => {
        this.changedLaps.next(data)
        this.allLaps = data;
      });
  }
  updateLap(lap: ILap) {
    this.http.put<ILap[]>(this.configUrl + 'Lap/' + lap.lapId, lap).subscribe(
      (data: ILap[]) => {
        this.changedLaps.next(data)
        this.getClubs();
      });
  }
  removeLap(lapID: number) {
    this.http.delete<ILap[]>(this.configUrl + 'Lap/' + lapID).subscribe(
      (data: ILap[]) => {
        this.changedLaps.next(data)
        this.getClubs();
      });
  }
  addLap(lap: ILap) {
    this.http.post<ILap[]>(this.configUrl + 'Lap/', lap).subscribe(
      (data: ILap[]) => {
        this.changedLaps.next(data);
        this.getClubs();
      });
  }

  getAutoStarts(club: IClub) {
    console.log("Retrieving AutoStarts for club")
    return this.http.get<any[]>(this.configUrl + 'AutoStart/' + club.clubModelId)
  }


  // RFID MOD
  addRFIDReader(rfidReader: IRFIDReader) {
    this.http.post<IRFIDReader>(this.configUrl + 'RFIDReader/', rfidReader).subscribe(
      (data: IRFIDReader) => {
        this.getClubs();
      })
  }
  updateRFIDReader(rfidReader: IRFIDReader) {
    console.log(rfidReader)
    this.http.put<IRFIDReader>(this.configUrl + 'RFIDReader/' + rfidReader.rfidReaderModelId, rfidReader).subscribe(
      (data: IRFIDReader) => {
        this.getClubs();
      })
  }
  removeRFIDReader(rfidReader: IRFIDReader) {
    console.log(rfidReader)
    this.http.delete<IRFIDReader>(this.configUrl + 'RFIDReader/' + rfidReader.rfidReaderModelId).subscribe(
      (data: IRFIDReader) => {
        alert(rfidReader.readerName + ' raderades från databasen');
        this.getClubs();
      });
  }
  getRFIDReadersByClub(clubModelId: number) {
    console.log('Retrieving RFID readers at club by clubId')
    return this.http.get<IRFIDReader[]>(this.configUrl + 'RFIDReader/ByClub/' + clubModelId);  
  }

  // BIKE BRAND MOD
  private changedBikeBrands = new ReplaySubject<IBikeBrand[]>();
  broadCastBikeBrands = this.changedBikeBrands.asObservable();
  getBikeBrands() {
    console.log('Retrieving all bikebrands in DB')
    this.http.get<IBikeBrand[]>(this.configUrl + 'BikeBrand/').subscribe(
      (data: IBikeBrand[]) => {
        this.allBikeBrands = data;
        this.changedBikeBrands.next(data)
      });
  }
  addBikeBrand(bikeBrand: IBikeBrand, picFile: any) {
    this.http.post<IBikeBrand[]>(this.configUrl + 'BikeBrand/', bikeBrand).subscribe(
      (data: IBikeBrand[]) => {
        if (bikeBrand.pictureUrl = 'exists') {
          this.updateBikeBrandPicture(bikeBrand, picFile)
        }
          else this.getBikeBrands(); 
      });
  };
  updateBikeBrandPicture(bikeBrand: IBikeBrand, picFile: any) {
    //Get original filetype
    var fileType = picFile.name.split('.');
    var lastIdx = fileType.lastIndexOf;
    const formData = new FormData();

    formData.append('file', picFile, bikeBrand.bikeBrand + '.' + fileType[fileType.length - 1])

    this.http.post<boolean>(this.configUrl + 'BikeBrand/UploadImage/', formData).subscribe(
      (data: boolean) => {
        if (data)
          this.getBikeBrands();
        else
          alert('could not update picture!')
      })
  };
  updateBikeBrand(bikeBrand: IBikeBrand, picFile:any) {
    this.http.put<IBikeBrand>(this.configUrl + 'BikeBrand/' + bikeBrand.id, bikeBrand).subscribe(
      (data: IBikeBrand) => {
        if (bikeBrand.pictureUrl == 'exists') {
          this.updateBikeBrandPicture(bikeBrand, picFile)
        }
        else
          this.getBikeBrands();
      })
  };
  removeBikeBrand(bikeBrand: IBikeBrand) {
    this.http.delete<IBikeBrand>(this.configUrl + 'BikeBrand/' + bikeBrand.id).subscribe(
      (data: IBikeBrand) => {
        //this.snackbar.open(data.bikeBrand +' har tagits bort från tillverkare')
        alert(data.bikeBrand + ' har tagits bort från tillverkare')
        this.getBikeBrands();
      });
  }

  // SERIE
  private changedSeries = new ReplaySubject<ISerie[]>();
  broadCastSeries = this.changedSeries.asObservable();
  getSeries() {
    console.log('Retrieving all series in DB')
    this.http.get<ISerie[]>(this.configUrl + 'SeriesModels/').subscribe(
      (data: ISerie[]) => this.changedSeries.next(data)
    );
  }
  addSerie(serie: ISerie) {

    this.http.post<ISerie>(this.configUrl + 'SeriesModels/', serie).subscribe(
      (data: ISerie) => {
        //this.changedMembers.next(data)

        this.getSeries();
      });
  }
  updateSerie(serie: ISerie) {
    this.http.put<ISerie>(this.configUrl + 'SeriesModels/' + serie.id, serie).subscribe(
      (data: ISerie) => {
        //this.changedMembers.next(data)
        alert(serie.serieName + ' uppdaterades i databasen');
        this.getSeries();
      });
  }
  removeSerie(serie: ISerie) {
    this.http.delete<ISerie>(this.configUrl + 'SeriesModels/' + serie.id).subscribe(
      (data: ISerie) => {
        //this.changedMembers.next(data)
        alert(serie.serieName + ' raderades från databasen');
        this.getSeries();
      });
  }

  //EVENT
  private changedEventTypes = new ReplaySubject<IEventType[]>();
  broadCastEventTypes = this.changedEventTypes.asObservable();
  private changedEvents = new ReplaySubject<IEvent[]>(); //Here I took out a 1
  broadCastEvents = this.changedEvents.asObservable();
  getEventTypes() {
    console.log('Retrieving all EventTypes in DB')
    this.http.get<IEventType[]>(this.configUrl + 'EventType/').subscribe(
      (data: IEventType[]) => {
        this.changedEventTypes.next(data)
      });

  }
  getEvents() {
    console.log('Retrieving all Events in DB')
    this.http.get<IEvent[]>(this.configUrl + 'Event/').subscribe(
      (data: IEvent[]) => {  
        this.changedEvents.next(data)
      });
  }
  getEventsByType(type: number) {
    console.log('Retrieving events by type')
    this.http.get<IEvent[]>(this.configUrl + 'Event/ByType/' + type).subscribe(
      (data: IEvent[]) => {
        this.changedEvents.next(data)
      });
  }
  getEventsByClub(clubModelId: number) {
    console.log('Retrieving events at club by clubId')
    if (clubModelId)
    this.http.get<IEvent[]>(this.configUrl + 'Event/ByClub/' + clubModelId).subscribe(
      (data: IEvent[]) => {
        this.changedEvents.next(data)
      });
  }
  addEvent(event: IEvent) {
    this.http.post<any>(this.configUrl + 'Event/', event).subscribe(
      (data: any) => {
        this.getEvents();
      }
    )
  }
  getEventLaps(event: IEvent) {
    console.log('Retrieving laps in Event by IEvent')
    return this.http.post<ILap[]>(this.configUrl + 'Event/GetEventLaps', event);
  }
  getEventBySerie(serieId: number) {
    console.log('Retrieves events in Serie')
    return this.http.get<IEvent[]>(this.configUrl + 'Event/BySerie/' +serieId)
  }
  removeEvent(event: IEvent) {
    this.http.delete<IEvent>(this.configUrl + 'Event/' + event.eventId).subscribe(
      (data: IEvent) => {
        //this.changedEvents.next(data)
        alert('Eventet har tagits bort från databasen');
        this.getEvents();
      });
  }
  updateEvent(eventUpdate: IEvent) {
    this.http.put<IEvent>(this.configUrl + 'Event/' + eventUpdate.eventId, eventUpdate).subscribe(
      (data: IEvent) => {
        alert(eventUpdate.eventName + ' uppdaterades i databasen');
        this.getEvents();
      });
  }
  updateLapsInEvent(lapsInEvent: ILapInEvent[]) {
    this.http.put<ILapInEvent[]>(this.configUrl + 'LapInEvent/List/', lapsInEvent).subscribe(
      (data: any) => {
        //alert('Varven uppdaterades i databasen');
        this.getEvents();
      });
  }

  //AutoStart



  //RegisteredParticipants
  // Add is made via updateevent!!
  getRegisteredParticipantsInRace(eventId: number) {
    console.log('Retrieves allready registered participants in race');
    return this.http.get<IMemberInCompetition[]>(this.configUrl + 'MemberInCompetitions/ByEvent/' +eventId);
  }

  //ClassModel
  //Adding via updateEvent
  getClassModelsByEvent(eventId: number) {
    console.log('Retireves allready defined classModels in race');
    return this.http.get<IClass[]>(this.configUrl + 'Class/ByEvent/' + eventId);
  }

  //PI's
  private changedPis = new ReplaySubject<IPi[]>();
  broadCastPis = this.changedPis.asObservable();
  getActivePis() {
    console.log('Retrieving all Active Pis from DB')
    this.http.get<IPi[]>(this.configUrl + 'ActivePis/').subscribe(
      (data: IPi[]) => {
        this.changedPis.next(data)
      });
  }

  //Point Tables
  getPointTables() {
    console.log('Retrieving all Point Tables in DB');
    return this.http.get<IPoint[]>(this.configUrl + 'Point/');
  }
  getHeatPointTables() {
    console.log('Retrieving all HeatPoint Tables in DB');
    return this.http.get<IPoint[]>(this.configUrl + 'HeatPoint/');
  }
  
  //Start types
  getStartTypes() {
    console.log('Retrieving all Start Types in DB');
    return this.http.get<IStartType[]>(this.configUrl + 'StartType/')
  }

  //Action EVENTS
  startEvent(event: IEvent) {
    console.log('Sending start event to server!')
    return this.http.post<any>(this.configUrl + 'Event/StartEvent', event).subscribe(
      (data: any) => {
        return data
      }
    )
  }
  startTagCheck(event: IEvent) {
    console.log('Sending start TagCheck to server!')
    return this.http.post<any>(this.configUrl + 'Event/StartTagCheck', event).subscribe(
      (data: any) => {
        return data
      }
    )
  }
  pauseEvent(event: IEvent) {
    console.log('Sending pause event to server!')
    this.http.post<any>(this.configUrl + 'Event/PauseEvent', event).subscribe(
      (data: any) => {
        return data
      }
    )
    this.http.post<any>(this.configUrl + 'TimeRead/PauseHeat/' + event.eventId, null).subscribe(
      (data: any) => {
        return data
      }
    )
  }
  stopEvent(event: IEvent) {
    console.log('Sending stop event to server!')
    this.http.post<any>(this.configUrl + 'Event/StopEvent', event).subscribe(
      (data: any) => {
        return data
      }
    )
  }
  stopTagCheck(event: IEvent) {
    console.log('Sending stop TagCheck to server!')
    this.http.post<any>(this.configUrl + 'Event/StopTagCheck', event).subscribe(
      (data: any) => {
        return data
      }
    )
  }
  sendStartTime(timeRead: ITimeRead) {
    return this.http.post<any>(this.configUrl + 'TimeRead/ButtonStart', timeRead).subscribe(
      (data: any) => {
        return data
      }
    )
  }
  //TimeReads

  // GET ALL MEMBERS AND TIMES IN EVENT
  private changedMembersTimesEvent = new ReplaySubject<IMember[]>();
  broadCastMembersTimesEvent = this.changedMembersTimesEvent.asObservable();
  getMembersTimesEvent(event: IEvent) {
    console.log('Retrieving all Members AND times for members in Event by EventId')
    this.http.get<IMember[]>(this.configUrl + 'Member/ByEvent/' + event.eventId).subscribe(
      (data: IMember[]) => {
        this.changedMembersTimesEvent.next(data)
      });
  }

  getMembersTimesEventDirect(event: IEvent) {
    console.log('Retrieving all Members AND times for members in Event by EventId')
    return this.http.get<IMember[]>(this.configUrl + 'Member/ByEvent/' + event.eventId);
  }
  getMemberTimeReads(member: IMember) {
    return this.http.get<ITimeRead[]>(this.configUrl + 'TimeRead/' + member.svemoId)
  }

  //GET ALL INTERMEDIATES FOR ONE RACER IN ONE EVENT
  private changedIntermediateTimes = new ReplaySubject<IIntermediateTimeModel[]>();
  broadCastIntermediateTimes = this.changedIntermediateTimes.asObservable();
  getMemberIntermediatesEvent(eventId: number, svemoId: number) {
    console.log('Retrieving all intermediate times for one member and event by eventId and svemoId')
    this.http.get<IIntermediateTimeModel[]>
      (this.configUrl + 'IntermediateTime/ByEventSvemo/' + eventId + '/' + svemoId).subscribe(
        (data: IIntermediateTimeModel[]) => {
          this.changedIntermediateTimes.next(data);
          return data;
        });
  }

  //GET ALL LAP TIMES FOR ONE RACER IN ONE EVENT
  private changedLapTimes = new ReplaySubject<IIntermediateTimeModel[]>();
  broadCastLapTimes = this.changedLapTimes.asObservable();
  getMemberLapTimesEvent(eventId: number, svemoId: number) {
    console.log('Retrieving all lap times for one member and event by eventId and svemoId')
    this.http.get<IIntermediateTimeModel[]>
      (this.configUrl + 'LapTime/ByEventSvemo/' + eventId + '/' + svemoId).subscribe(
        (data: IIntermediateTimeModel[]) => {
          this.changedLapTimes.next(data);
          return data;
        });
  }

   //GET ALL CONFIRMED TIMEREADS FOR ONE RACER IN ONE EVENT
  private changedConfirmedTimeReads = new ReplaySubject<IConfirmedTimeRead[]>();
  broadCastConfirmedTimeReads = this.changedConfirmedTimeReads.asObservable();
  getMemberConfirmedTimeReadsEvent(eventId: number, svemoId: number) {
    console.log('Retrieving all confirmed timereads for one member and event by eventId and svemoId')
    this.http.get<IConfirmedTimeRead[]>
      (this.configUrl + 'ConfirmedTimeRead/ByEventSvemo/' + eventId + '/' + svemoId).subscribe(
        (data: IConfirmedTimeRead[]) => {
          this.changedConfirmedTimeReads.next(data);
          return data;
        });
  }



  //LAPTIMES
  getAllMemberLapTimes(member: IMember) {
    console.log('Retrieving all lap times for one member svemoId')
    return this.http.get<IIntermediateTimeModel[]> (this.configUrl + 'LapTime/ByMember/' + member.svemoId)
  }
  updateLapTime(lapTime: ILapTimeModel) {
    console.log('Updating laptime')
    return this.http.put<ILapTimeModel>(this.configUrl + 'Laptime/' + lapTime.timeReadID, lapTime);
  }
  addLapTime(lapTime: ILapTimeModel) {
    console.log('Adding Lap to DB')
    return this.http.post<ILapTimeModel>(this.configUrl + 'Laptime/', lapTime);
  }
  removeLapTime(lapTime: ILapTimeModel) {
    console.log('Removing Lap from DB')
    return this.http.delete<ILapTimeModel>(this.configUrl + 'LapTime/'+ lapTime.timeReadID)
  }



  getMemberProfileUrl(svemoId: number) {

  }


  private changedLapSetUps = new BehaviorSubject<ILapSetUp[]>([]);
  broadCastLapSetUps = this.changedLapSetUps.asObservable();

  private changedTimeReads = new ReplaySubject<ITimeRead[]>();
  broadCastTimeRead = this.changedTimeReads.asObservable();


  addMembersInCompetition(membersInCompetition: IMemberInCompetition[]) {
    this.http.post<any>(this.configUrl + 'MemberInCompetitions/', membersInCompetition).subscribe(
      (data: any) => {
        alert('ParticipantsAddedToDB')
        return data
      }
    )
  }
  putMemberInCompetition(memberInCompetition: IMemberInCompetition) {
    this.http.put<any>(this.configUrl + 'MemberInCompetitions/'+memberInCompetition.id, memberInCompetition).subscribe(
      (data: any) => {
        //alert('ParticipantUpdatedDB')
        //return data
      }
    )
  }

  getTimeReadsEvent(event: IEvent) {
    console.log('Retrieving all timereads for one event by eventId')
    this.http.get<ITimeRead[]>(this.configUrl + 'TimeRead/GetTimesEvent/' + event.eventId).subscribe(
      (data: ITimeRead[]) => {
        this.changedTimeReads.next(data)
      });
  }
}
