import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { IClass, IClub, IEvent, IMember, ISerie } from '../../models/interfaceModels';
import { ActiveuserService } from '../../services/activeuser.service';
import { DataRoomService } from '../../services/data-room.service';
import { DbService } from '../../services/db.service';
import { SignalRService } from '../../services/signal-r.service';
import { DisplayMember } from '../run-competition/run-competition.component';

@Component({
  selector: 'app-results-competition',
  templateUrl: './results-competition.component.html',
  styleUrls: ['./results-competition.component.css']
})
export class ResultsCompetitionComponent implements OnInit {

  constructor(private dbService: DbService,
    public dataRoom: DataRoomService,
    private activeUserService: ActiveuserService,) { }

  public activeUser: IMember;
  private broadCastActiveUser: any;

  //Keep track of clubs
  public clubs: IClub[];
  private broadCastClubs: any;
  public selectedClub: IClub;

  //Event data
  public allEvents: IEvent[] = [];
  private broadCastallEvents: any;
  public selectedEvent: IEvent;
  public allClasses: string[] = [];
  public dummyallClasses: { name: string, idx: number }[] = [];
  public displayMembers: DisplayMember[][] = [];
  public headerArray: [{ header: string, field: string }][] = [];

  //Series data
  public displayCompetition: DisplayCompetition[];
  //public totalSerie: DisplayTotalCompetition[];
  public totalSerie : DisplayTotalCompetition[];


  //Series data
  public allSeries: ISerie[] = [];
  public selectedSerie: ISerie;
  private broadCastAllSeries: any;

  ngOnInit(): void {
    this.broadCastActiveUser = this.activeUserService.currentUser.subscribe(data => {
      this.activeUser = data
      this.selectedClub = this.activeUser.clubModel
      this.broadCastAllSeries = this.dbService.broadCastSeries.subscribe(data => {
        if (this.selectedClub) {
          this.allSeries = data.filter(series => series.clubModelId == this.selectedClub.clubModelId)
        }
      })
    })
    this.broadCastClubs = this.dbService.broadCastClubs.subscribe(data => {
      this.clubs = data;

    })
    this.broadCastallEvents = this.dbService.broadCastEvents.subscribe(data => {
      if (this.selectedSerie) {
        this.allEvents = data.filter(event =>
          event.seriesModelId == this.selectedSerie.id && event.eventType.type == 'Tävling' && event.hasStarted > 2);
        this.allEvents.forEach(event => {
          this.dbService.getRegisteredParticipantsInRace(event.eventId).subscribe(data => {
            event.memberInCompetition = data;
            var displayMember = this.dataRoom.createDisplayMembers(data);
            var maxLaps = 0;
            var headers: [{ header: string, field: string }];

            this.dbService.getMembersTimesEventDirect(event).subscribe(data => {
              if (data) {
                //console.log(data)
                //Create headers for all laps
                data.forEach(member => {
                  displayMember.find(dispMember => dispMember.svemoId == member.svemoId).confirmedTimeReadModel = member.confirmedTimeReadModel;
                  displayMember.find(dispMember => dispMember.svemoId == member.svemoId).intermediateTimeModel = member.intermediateTimeModel;
                  displayMember.find(dispMember => dispMember.svemoId == member.svemoId).lapTimeModel = member.lapTimeModel;

                  member = this.dataRoom.totalTime(displayMember.find(dispMember => dispMember.svemoId == member.svemoId))
                  displayMember.find(dispMember => dispMember.svemoId == member.svemoId).nbrLaps = member.lapTimeModel.length;
                  // If this member has more laps then allready added to header add new headers
                  if (member.lapTimeModel.length > maxLaps) {
                    var addedLaps = member.lapTimeModel.length;
                    //console.log(this.selectedEvent.lapinEvent[0].lapModel.lapName)
                    if (event.eventType.case != 'Heat') {
                      while (maxLaps < member.lapTimeModel.length) {
                        if (!headers)
                          headers = [{ header: event.lapinEvent[0].lapModel.lapName, field: 'lapTimeModel[' + maxLaps + ']' }];
                        else
                          headers.push({ header: event.lapinEvent[0].lapModel.lapName, field: 'lapTimeModel[' + maxLaps + ']' });
                        maxLaps++;
                      }
                    }
                    else {
                      while (maxLaps < member.lapTimeModel.length) {
                        if (!headers)
                          headers = [{ header: 'Heat 1', field: 'lapTimeModel[' + maxLaps + ']' }];
                        else
                          headers.push({ header: 'Heat ' + maxLaps, field: 'lapTimeModel[' + maxLaps + ']' });
                        maxLaps++;
                      }
                    }
                  }
                })
              }
            })
            this.displayMembers.push(displayMember);
            this.headerArray.push(headers);
            this.allClasses = this.dataRoom.uniqueClasses(displayMember);
            this.displayCompetition.push(this.createDisplayCompetition(event, displayMember, this.dataRoom.uniqueClasses(displayMember), headers));
            this.displayCompetition.sort((n1, n2) => {
              if (n1.event.eventName > n2.event.eventDate) {
                return 1;
              }
              if (n1.event.eventDate < n2.event.eventDate) {
                return -1;
              }

              return 0;
            })
          })
        })
      }
    })
  }

  ngOnDestroy(): void {
    //DB services
    this.broadCastActiveUser.unsubscribe();
    this.broadCastClubs.unsubscribe();
    this.broadCastallEvents.unsubscribe();
    this.broadCastAllSeries.unsubscribe();

  }
  onClubChange(event: any) {
    this.selectedClub = event.value;
    this.broadCastAllSeries = this.dbService.broadCastSeries.subscribe(data => {
      if (this.selectedClub) {
        this.allSeries = data.filter(series => series.clubModelId == this.selectedClub.clubModelId)
        if (this.allSeries.length > 0) {
          this.selectedSerie = this.allSeries[0];
          this.dbService.getEventsByClub(this.selectedClub.clubModelId); 
        }
      }
    })
  }

  onSerieChange(test: any) {
    this.dbService.getEventsByClub(this.selectedClub.clubModelId);
    this.displayCompetition = [];
  }
  comparer(o1: any, o2: any): boolean {
    // if possible compare by object's name, and not by reference.
    return o1 && o2 ? o1.name === o2.name : o2 === o2;
  }

  console() {
   /* console.log(this.displayCompetition)*/
    console.log(this.totalSerie)
  }

  public createDisplayCompetition(even: IEvent, dispMembers: DisplayMember[], eventCla: string[], head: [{ header: string, field: string }]) {

    var displayCompetition: DisplayCompetition = {
      event : even,
      displayMembers: dispMembers,
      eventClasses : eventCla,
      headers : head,
    }
    return displayCompetition
  }

  public createTotalSerie(competitionEvents: DisplayCompetition[], className:string): DisplayTotalCompetition[] {
    var totalDisplay: DisplayTotalCompetition[] = [];
    var eventidx = 0;
    competitionEvents.forEach(event => {
      event.displayMembers.forEach(member => {
        var memberExists = totalDisplay.find(displayMem => displayMem.svemoId == member.svemoId)
        if (!memberExists) {
          var displayTotal: DisplayTotalCompetition = {
            svemoId: member.svemoId,
            firstName: member.firstName,
            lastName: member.lastName,
            //classArray: [],
            //pointArray: [],
            infoArray: [],
            totalPoints: 0,
          }
          for (let i = 0; i < eventidx + 1; i++) {
            if (i < eventidx) {
              //displayTotal.classArray.push('');
              //displayTotal.pointArray.push(0);
              displayTotal.infoArray.push({
                class: '',
                points: 0,
                eventName: competitionEvents[i].event.eventName,
                eventDate: competitionEvents[i].event.eventDate,
              })
            }
            else {
              displayTotal.infoArray.push({
                class: member.className,
                points: member.points,
                eventName: competitionEvents[eventidx].event.eventName,
                eventDate: competitionEvents[eventidx].event.eventDate,
              })

              //displayTotal.classArray.push(member.className);
              //displayTotal.pointArray.push(member.points);
              //displayTotal.totalPoints = displayTotal.totalPoints + member.points;
            }
          }
         
          totalDisplay.push(displayTotal);
        }
        else {
          //if (memberExists.classArray.length < eventidx+1) {
          if (memberExists.infoArray.length < eventidx + 1) {
            for (let i = memberExists.infoArray.length; i < eventidx; i++) {
              //memberExists.classArray.push('');
              //memberExists.pointArray.push(0);
              memberExists.infoArray.push({
                class: '',
                points: 0,
                eventName: competitionEvents[i].event.eventName,
                eventDate: competitionEvents[i].event.eventDate,
              })
            }
          }
          memberExists.infoArray.push({
            class: member.className,
            points: member.points,
            eventName: competitionEvents[eventidx].event.eventName,
            eventDate: competitionEvents[eventidx].event.eventDate,
          })
         // memberExists.classArray.push(member.className);
         // memberExists.pointArray.push(member.points);

        }
      })
      eventidx++;
    })
   
    totalDisplay.forEach(participant => {
      var totalPoints: number = 0;
      var nbrResultsToCount = this.selectedSerie.eventsToCount;

      if (participant.infoArray.length < competitionEvents.length) {
        for (let i = participant.infoArray.length; i < competitionEvents.length; i++) {
          participant.infoArray.push({
            class: '',
            points: 0,
            eventName: competitionEvents[i].event.eventName,
            eventDate: competitionEvents[i].event.eventDate,
          });
         // participant.pointArray.push(0);
        }
      }


      //if (participant.pointArray.length < nbrResultsToCount) {
      //  nbrResultsToCount = participant.pointArray.length;
      //}
      
      
      for (let i = 0; i < nbrResultsToCount; i++) {
          totalPoints = totalPoints + participant.infoArray.sort((n1, n2) => n2.points - n1.points)[i].points;
        //totalPoints = totalPoints + participant.pointArray.sort((n1, n2) => n2 - n1)[i];
      }
      participant.totalPoints = totalPoints;
      participant.infoArray.sort((n1, n2) => {
        if (n1.eventDate > n2.eventDate) {
          return 1;
        }

        if (n1.eventDate < n2.eventDate) {
          return -1;
        }

        return 0;
      }
      )})

    return totalDisplay.filter(data => data.infoArray[1].class == className);
    //return totalDisplay.filter(data=> data.classArray[1] == className);
  }


}

export interface DisplayTotalCompetition {
  svemoId: number;
  firstName: string;
  lastName: string;
  infoArray: InfoArray[];
  //classArray: string[];
  //pointArray: number[];
  totalPoints: number;
}

export interface InfoArray {
  class: string;
  points: number;
  eventName: string;
  eventDate: string;
}

export interface DisplayCompetition {
  event : IEvent,
  displayMembers: DisplayMember[],
  eventClasses: string[],
  headers: [{ header: string, field: string }]
}

