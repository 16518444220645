import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

/*  public resetUrl: string = 'http://localhost:4200/#/auth/resetPassword'*/
  public resetUrl: string = 'https://www.tracktime.se/#/auth/resetPassword'

/*  public configUrl: string = 'http://localhost:65362/api/';*/
  public configUrl: string = 'https://apinew.wmstime.se/api/';

/*  public configUrlSignalR: string = 'http://localhost:65362/signalRClient';*/
  public configUrlSignalR: string = 'https://apinew.wmstime.se/signalRClient';


  public snackBarTiming: number = 3000;

}
