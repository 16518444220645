
 <!--HEADER--> 
<mat-card fxLayoutalign="start center" style="margin-top:1rem; margin-bottom:0rem; padding-top:0.5rem; padding-bottom:0rem">
  <div style="height:4rem" fxLayout="row-wrap" fxLayout.lt-sm="column" fxLayoutGap="2rem" fxLayoutGap.lt-sm="0rem">
    <h3 style="margin-left:2rem; font-family:Roboto; font-weight:500">Genomför tävling: </h3>
    <mat-form-field appearance="fill">
      <mat-label>Välj klubb:</mat-label>
      <mat-select [(value)]="selectedClub"
                  [compareWith]="comparer"
                  style="padding:0;margin:0"
                  (selectionChange)="onClubChange($event)">

        <mat-option *ngFor="let club of clubs" [value]="club">{{club.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field *ngIf="selectedClub" appearance="fill">
      <mat-label>Välj event:</mat-label>
      <mat-select [(value)]="selectedEvent"
                  (selectionChange)="onEventChange($event)">

        <mat-option *ngFor="let event of allEvents" [value]="event">{{event.eventName}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-card>
<mat-tab-group style="background-color:whitesmoke; margin-top:0rem">
  <!--FÖRE START-->
  <mat-tab label="Före start" fxFlex fxLayoutalign="center center">
    <div fxFlex fxLayoutalign="center center" *ngIf="!selectedEvent">

      <span>Välj klubb och event!</span>

    </div>
    <div *ngIf="selectedEvent" fxLayout="row" fxLayout.lt-sm="column">

      <!--Info column-->
      <mat-card fxFlex="30" fxLayout="column" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0rem">
        <mat-form-field>
          <mat-label>Event ID:</mat-label>
          <input matInput value="{{selectedEvent.eventId}}" disabled>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Datum:</mat-label>
          <input matInput value="{{selectedEvent.eventDate}}" disabled>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Namn på tävling:</mat-label>
          <input matInput value="{{selectedEvent.eventName}}" disabled>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ingår i serie:</mat-label>
          <input matInput value="{{serieNamn(selectedEvent.seriesModelId)}}" disabled>
          <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="info(1)" matTooltip="Info kring vilka övriga deltävlingar som finns i serie">
            <mat-icon style="font-size:2rem">info_outline</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Tävlings form:</mat-label>
          <input matInput value="{{selectedEvent.eventType.case}}" disabled>
          <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="info(2)"  matTooltip="Info kring läsare och varv i tävlingen">
            <mat-icon style="font-size:2rem">info_outline</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Start form:</mat-label>
          <input matInput value="{{startTypeName(selectedEvent.startTypeId)}}" disabled>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Antal deltagare:</mat-label>
          <input matInput value="{{nbrParticipants}}" disabled>
          <button mat-button matSuffix mat-icon-button aria-label="Clear" (click)="info(3)"  matTooltip="Anmälning och transponder kontroll">
            <mat-icon style="font-size:2rem">info_outline</mat-icon>
          </button>
        </mat-form-field>
      </mat-card>

      <!--NICE PICTURE-->
      <mat-card *ngIf="infoType==0" fxFlex="70" fxLayout="column" fxLayout.lt-sm="column" fxLayoutGap="2rem" fxLayoutGap.lt-sm="0rem">
        <img class="img" width="100%" src="/assets/img/niceDirtBike2.jpg" />
      </mat-card>

      <!--LISTA PÅ EVENTS I SERIE-->
      <mat-card *ngIf="infoType==1" fxFlex="70" fxLayout="column" fxLayout.lt-sm="column" fxLayoutGap="2rem" fxLayoutGap.lt-sm="0rem">
        <h4 style="margin-left:2rem; font-family:Roboto; font-weight:500">Deltävlingar i samma serie: </h4>
        <p-table [value]="eventsInSerie" dataKey="eventId">
          <ng-template pTemplate="header">
            <th fxFlex="50" fxLayoutAlign="start center" style="margin-left:1rem" pSortableColumn="eventName">Namn<p-sortIcon field="eventName"></p-sortIcon></th>
            <th fxFlex="50" fxLayoutAlign="start center" pSortableColumn="eventDate">Datum<p-sortIcon field="eventDate"></p-sortIcon></th>
          </ng-template>
          <ng-template pTemplate="body" let-event>
            <tr fxFlexLayout="row" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:3rem">
              <td fxFlex="50">
                <span>{{event.eventName}}</span>
              </td>
              <td fxFlex="50">
                <span>{{event.eventDate}}</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </mat-card>

      <!--VARV SOM INGÅ I TÄVLINGEN-->
      <mat-card *ngIf="infoType==2" fxFlex="70" fxLayout="column" fxLayout.lt-sm="column" fxLayoutGap="2rem" fxLayoutGap.lt-sm="0rem">
        <h4 style="margin-left:2rem; font-family:Roboto; font-weight:500">Varv som ingår i tävling: </h4>
        <p-table [value]="selectedEvent.lapinEvent" dataKey="lapId">
          <ng-template pTemplate="header">
            <th fxFlex="3rem"></th>
            <th fxFlex="30" fxLayoutAlign="start center" style="margin-left:1rem" pSortableColumn="lapModel.lapName">Namn<p-sortIcon field="lapModel.lapName"></p-sortIcon></th>
            <th fxFlex="20" fxLayoutAlign="start center">Varv nummer:</th>
            <th fxFlex fxLayoutAlign="start center">Antal stationer:</th>
          </ng-template>
          <ng-template pTemplate="body" let-lapinEvent let-expanded="expanded">
            <tr fxFlexLayout="row" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:3rem">
              <td fxFlex="3rem" style="padding: 0.65rem; margin:0px; ">
                <button style="height:2rem; width:2rem" type="button"
                        pButton pRipple [pRowToggler]="lapinEvent"
                        class="p-button-text p-button-rounded p-button-plain p-button-sm"
                        [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
              </td>
              <td fxFlex="30">
                <span>{{lapinEvent.lapModel.lapName}}</span>
              </td>
              <td fxFlex="20">
                <span>{{lapinEvent.lapNbr}}</span>
              </td>
              <td fxFlex>
                <span>{{lapinEvent.lapModel.lapSetUps.length}}</span>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-lapModel>
            <mat-card class="MatCard-mw">
              <h4 style="margin-left:2rem; font-family:Roboto; font-weight:500">Ingående läsare: </h4>
              <p-table [value]="lapModel.lapModel.lapSetUps">
                <ng-template pTemplate="header">
                  <th fxFlex="25" fxLayoutAlign="start center" style="margin-left:1rem">Position</th>
                  <th fxFlex="30" fxLayoutAlign="start center">Namn</th>
                  <th fxFlex="20" fxLayoutAlign="start center">Antenn</th>
                  <th fxFlex fxLayoutAlign="center center">Status</th>
                </ng-template>
                <ng-template pTemplate="body" let-lapSetUp>
                  <tr fxFlexLayout="row" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:3rem">
                    <td fxFlex="25">
                      <span>{{lapSetUp.name}}</span>
                    </td>
                    <td fxFlex="30">
                      <span>{{lapSetUp.readerId}}</span>
                    </td>
                    <td fxFlex="20">
                      <span>{{lapSetUp.antennaId}}</span>
                    </td>
                    <td fxFlex fxLayoutAlign="center center">
                      <mat-icon [ngStyle]="{'color':getTheColor2(lapSetUp.readerId)}">circle</mat-icon>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </mat-card>
          </ng-template>
        </p-table>
        <mat-card class="MatCard-mw">
          <h4 style="margin-left:2rem; font-family:Roboto; font-weight:500">Läsar status: </h4>
          <div fxLayout="row" *ngFor="let reader of readersInEvent">
            <span style="width:10rem">{{reader}}</span>
            <mat-icon [ngStyle]="{'color':getTheColor2(reader)}">circle</mat-icon>
          </div>
        </mat-card>
      </mat-card>

      <!--LIST OF RACERS-->
      <mat-card *ngIf="infoType==3" fxFlex="70" fxLayout="column" fxLayout.lt-sm="column" fxLayoutGap="0rem" fxLayoutGap.lt-sm="0rem">

        <h4 style="margin-left:2rem; font-family:Roboto; font-weight:500">Deltagare:</h4>

        <p-table [value]="selectedEvent.memberInCompetition" dataKey="startNbr"
                 [scrollable]="true" scrollHeight="400px">
          <ng-template pTemplate="header">
            <div fxLayoutAlign="start center" style="margin-left:0;margin-bottom:0.25rem; height:3rem;background-color:rgb(13,130,197)">
              <th fxFlex="10" fxLayoutAlign="start center" pSortableColumn="startNbr">#<p-sortIcon field="startNbr"></p-sortIcon></th>
              <th fxHide.lt-sm fxFlex="50" fxLayoutAlign="start center">Namn</th>
              <th fxHide.gt-xs fxFlex="30" fxLayoutAlign="start center">Namn</th>
              <th fxFlex="20" fxLayoutAlign="start center" pSortableColumn="className">Klass<p-sortIcon field="className"></p-sortIcon></th>
              <th fxFlex fxLayoutAlign="start center" pSortableColumn="isPresent">Bet.<p-sortIcon field="isPresent"></p-sortIcon></th>
              <th fxFlex fxLayoutAlign="start center" pSortableColumn="isChecked">Tag<p-sortIcon field="isChecked"></p-sortIcon></th>
            </div>
          </ng-template>
          <ng-template pTemplate="body" let-memberInCompetition>
            <tr style="padding: 0px; margin:0px; height:2rem; align-content:center">
              <td fxFlex="10" style="margin:0px; padding:0px">
                <span><b>{{memberInCompetition.startNbr}}</b></span>
              </td>
              <td fxHide.lt-sm fxFlex="50" style="margin:0px; padding:0px">
                <span>{{dataRoom.showMemberName(memberInCompetition.svemoId)}}</span>
              </td>
              <td fxHide.gt-xs fxFlex="30" style="margin:0px; padding:0px">
                <span>{{dataRoom.showMemberNameMini(memberInCompetition.svemoId)}}</span>
              </td>

              <td fxFlex="20" style="margin:0px; padding:0px">
                <span>{{memberInCompetition.className}}</span>
              </td>
              <td fxFlex fxLayoutAlign="start center">
                <mat-checkbox color="primary" [(ngModel)]="memberInCompetition.isPresent"></mat-checkbox>
              </td>
              <td fxFlex fxLayoutAlign="start center">
                <mat-checkbox color="primary" [(ngModel)]="memberInCompetition.isChecked" disabled></mat-checkbox>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="2rem" style="padding:0.5rem;">
          <button class="mw-btn" type="button" (click)="startTagCheck()" mat-raised-button color="primary" matTooltip="Starta kontroll av transponder">
            <span>Starta check</span>
          </button>
          <button class="mw-btn" type="button" (click)="stopTagCheck()" mat-raised-button color="primary" matTooltip="Stoppa kontroll av transponder">
            <span>Stoppa check</span>
          </button>
        </div>
      </mat-card>

    </div>

  </mat-tab>

  <!--START OCH GENOMFÖRANDE TAB-->
  <mat-tab label="Start och genomförande">
    <div *ngIf="selectedEvent" fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0rem">
      <!--INfo and Commands-->
      <mat-card fxFlex="20rem" fxLayout="column" fxLayoutAlign="start top" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0.5rem" style="padding:0.1rem">
        <mat-card fxLayout="column" class="MatCard-mw" style="margin:0.5rem;margin-top:0.5rem;  padding:0.5rem">
          <h4 style="margin:0.3rem; margin-left:1rem; font-family:Roboto; font-weight:500" fxLayoutAlign="start center"><u>RFID Status</u> </h4>
          <div fxLayout="row" fxLayoutAlign="start top" fxLayoutGap="1rem" style="margin-bottom:0.5rem">
            <span *ngFor="let reader of readersInEvent" fxLayoutAlign="start top">
              <mat-icon [ngStyle]="{'color':getTheColor2(reader)}" style="margin-left:1rem">circle</mat-icon><span>{{reader}}</span>
            </span>
          </div>

          <h4 style="margin:0.3rem; margin-left:1rem; font-family:Roboto; font-weight:500" fxLayoutAlign="start center"><u>RACE Status</u> </h4>
          <span fxLayoutAlign="start top" style="margin-bottom:0.5rem; margin-left:1rem">
            <mat-icon [ngStyle]="{'color':getTheColor(selectedEvent.hasStarted)}">circle</mat-icon>{{getTheColorText(selectedEvent.hasStarted)}}
          </span>
          <div class="MatCard-mw" style="margin:0.5rem;padding:0.5rem; background-color:whitesmoke" fxLayout="column">
            <h4 style="margin:0.3rem; margin-left:1rem; font-family:Roboto; font-weight:500" fxLayoutAlign="center center"><u>Start/Stop/Paus:</u></h4>
            <div fxFlex fxLayout="row" fxLayoutAlign="center top" style="border-radius:1rem">
              <button class="mw-addBtn-icon" (click)="onStartEvent(selectedEvent)" matTooltip="Starta tävling"><mat-icon>play_arrow</mat-icon></button>
              <button class="mw-pauseBtn-icon" (click)="onPauseEvent(selectedEvent)" matTooltip="Pausa tävling"><mat-icon>pause</mat-icon></button>
              <button class="mw-deleteBtn-icon" (click)="onStopEvent(selectedEvent)" matTooltip="Stoppa tävling"><mat-icon>stop</mat-icon></button>
            </div>
          </div>
          <div class="MatCard-mw" style="margin:0.5rem;padding:0.5rem; background-color:whitesmoke" fxLayout="column">
            <h4 style="margin:0.3rem; margin-left:1rem; font-family:Roboto; font-weight:500" fxLayoutAlign="center center"><u>Race Info:</u></h4>

            <div fxLayout="column">
              <div style="border-bottom:double" fxLayout="row">
                <span fxFlex="5rem">Klass</span>
                <span fxFlex="4rem" fxLayoutAlign="center center">Varv</span>
                <span fxFlex="5rem" fxLayoutAlign="center center">Max Tid</span>
              </div>
              <div fxLayout="row" *ngFor="let class of selectedEvent.classesInEvent">
                <span fxFlex="5rem">{{class.className}}</span>
                <span fxFlex="4rem" fxLayoutAlign="center center">{{class.nbrLaps}}</span>
                <span fxFlex="5rem" fxLayoutAlign="center center">{{class.maxTime}} h</span>
              </div>
            </div>
          </div>
        </mat-card>
      </mat-card>
      <!--TIDER OCH FÖRARE-->
      <mat-card fxFlex fxLayout="column" fxLayout.lt-sm="column" fxLayoutGap="0.5rem" style="padding:0.5rem">
        <p-table [value]="threeLatestTimes" dataKey="timeReadID"
                 [scrollable]="true" scrollHeight="100px">
          >
          <ng-template pTemplate="header">
            <div fxLayoutAlign="start center" class="mw_main_table_header">
              <!--<th fxFlex="6rem" fxLayoutAlign="center center" pSortableColumn="svemoId">Svemo<p-sortIcon field="svemoId"></p-sortIcon></th>-->
              <th fxFlex="8rem" fxLayoutAlign="center center" pSortableColumn="time">Tid<p-sortIcon field="time"></p-sortIcon></th>
              <th fxFlex="6rem" fxLayoutAlign="center center" pSortableColumn="readerId">Läsare<p-sortIcon field="readerId"></p-sortIcon></th>
              <th fxFlex="6rem" fxLayoutAlign="center center" pSortableColumn="antennaId">Ant.<p-sortIcon field="antennaId"></p-sortIcon></th>
              <th fxFlex="5rem" fxLayoutAlign="center center" pSortableColumn="status">Status<p-sortIcon field="status"></p-sortIcon></th>
              <th fxFlex="6rem" fxLayoutAlign="center center">Varv</th>
              <th fxFlex="6rem" fxLayoutAlign="center center">Varv#</th>
              <th fxFlex></th>
            </div>
          </ng-template>

          <ng-template pTemplate="body" let-threeLatestTimes let-expanded="expanded">
            <tr fxFlexLayout="row" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.7rem">
              <!--<td fxFlex="6rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                <span style="font-size:0.8rem"><b>{{threeLatestTimes.svemoId}}</b></span>
              </td>-->
              <td fxFlex="8rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                <span style="font-size:0.8rem">{{threeLatestTimes.time | date:'HH:mm:ss'}}</span>
              </td>
              <td fxFlex="6rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                <span style="font-size:0.8rem">{{threeLatestTimes.readerId}}</span>
              </td>
              <td fxFlex="6rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                <span style="font-size:0.8rem">{{threeLatestTimes.antennaId}}</span>
              </td>
              <td fxFlex="5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                <span style="font-size:0.8rem">{{threeLatestTimes.status}}</span>
              </td>
              <td fxFlex="6rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                <span style="font-size:0.8rem">{{ displayLapName(threeLatestTimes.lapID)}}</span>
              </td>
              <td fxFlex="6rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                <span style="font-size:0.8rem">{{threeLatestTimes.ladIdx}}</span>
              </td>
              <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
            </tr>
          </ng-template>

        </p-table>
        <!--<p-table [value]="selectedEvent.memberInCompetition" dataKey="timeReadID"-->
        <p-table [value]="displayMembers" dataKey="id" sortMode="multiple"
                 [scrollable]="true" scrollHeight="400px">
          <!--HEADER-->
          <ng-template pTemplate="header">
            <div fxLayoutAlign="start center" class="mw_main_table_header">
              <th fxHide.lt-sm style="width: 1.5rem"></th>
              <th fxFlex="2.5rem" fxLayoutAlign="start center" pSortableColumn="startNbr">#<p-sortIcon field="startNbr"></p-sortIcon></th>
              <!--<th fxFlex="4rem" fxLayoutAlign="start center" pSortableColumn="startNbr">#<p-sortIcon field="startNbr"></p-sortIcon></th>-->
              <th fxFlex="12rem" fxLayoutAlign="start center" pSortableColumn="firstName">Namn<p-sortIcon field="firstName"></p-sortIcon></th>
              <th fxFlex="5rem" fxLayoutAlign="start center" pSortableColumn="className">Klass<p-sortIcon field="className"></p-sortIcon></th>
              <th fxFlex="3rem" fxLayoutAlign="center center">Status</th>
              <th fxFlex="4rem" *ngFor="let header of headers" style="font-size:0.8rem">
                <span fxLayoutAlign="center center">{{header.header}}</span>
              </th>
            </div>
          </ng-template>
          <!--BODY-->
          <ng-template pTemplate="body" let-displayMember let-expanded="expanded">
            <tr fxFlexLayout="row" fxLayoutAlign="center center" fxLayoutGap="0" style="padding: 0px; margin:0px; height:2rem">
              <td fxLayout fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
                <button class="toggleBtn" fxLayout fxLayoutAlign="center center"
                        [pRowToggler]="displayMember">
                  <mat-icon *ngIf="!expanded">keyboard_arrow_right</mat-icon>
                  <mat-icon *ngIf="expanded">keyboard_arrow_down</mat-icon>
                </button>
              </td>
              <td fxFlex="2.5rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
                <span fxLayoutAlign="start center"><b>{{displayMember.startNbr}}</b></span>
              </td>
              <!--<td fxFlex="4rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
                <span fxLayoutAlign="start center"><b>{{displayMember.svemoId}}</b></span>
              </td>-->
              <td fxFlex="12rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
                <span fxLayoutAlign="start center">{{displayMember.firstName + ' ' + displayMember.lastName}}</span>
              </td>
              <td fxFlex="5rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
                <span fxLayoutAlign="start center">{{displayMember.className}}</span>
              </td>
              <td fxFlex="3rem" style="padding: 0rem; margin:0px; height:1.5rem" fxLayoutAlign="center center">
                <mat-icon style="padding: 0rem; margin:0px; font-size:0.8rem" *ngIf="displayMember.confirmedTimeReadModel" fxLayoutAlign="center center"
                          [ngStyle]="{'color':getStatusColor(displayMember)}">circle</mat-icon>
              </td>
              <td *ngFor="let time of displayMember.lapTimeModel" fxFlex="4rem" style="padding: 0rem; margin:0px; height:1.5rem" fxLayoutAlign="center center">
                <div fxFlexLayout="row">
                  <!--<span fxLayoutAlign="center center" style="font-size:0.5rem; font-style:italic">{{displayLapName(time.lapID)}} </span>-->
                  <span fxLayoutAlign="center center" style="font-size:0.7rem"> {{convertToMiniTime(time.lapTime)}} </span>
                </div>
              </td>
              <td fxFlex style="padding: 0rem; margin:0px; height:1.5rem">
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="rowexpansion" let-member>
            <div fxLayout="row" fxLayout.lt-md="column" style="border:double;border-radius:0.5rem; margin:0.5rem;">
              <!--ConfirmedTimeReads TABLE-->
              <p-table fxFlex="60" [value]="member.confirmedTimeReadModel" dataKey="SvemoId"
                       [scrollable]="true" scrollHeight="200px">
                <ng-template pTemplate="header">
                  <div fxLayoutAlign="center center" class="mw_sub_table_header" style="border-bottom:ridge; height:2rem">
                    <span><u><b>Tid stämplar</b></u></span>
                  </div>
                  <div fxLayoutAlign="start center" class="mw_sub_table_header" style="margin-bottom:0.25rem; font-size:0.7rem">
                    <th fxFlex="3rem" fxLayoutAlign="center center">ID</th>
                    <th fxFlex="5rem" fxLayoutAlign="center center">Tid</th>
                    <th fxFlex="4rem" fxLayoutAlign="center center">Läsare</th>
                    <th fxFlex="3rem" fxLayoutAlign="center center">Ant</th>
                    <th fxFlex="4rem" fxLayoutAlign="center center">Status</th>
                    <th fxFlex="4rem" fxLayoutAlign="center center">Varv#</th>
                    <th fxFlex></th>
                  </div>
                </ng-template>
                <ng-template pTemplate="body" let-confirmedTimeReadModel let-expanded="expanded">
                  <tr fxFlexLayout="row" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.7rem"
                      [style.color]="confirmedTimeReadModel.correctRead ? 'black' : 'red' ">
                    <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{confirmedTimeReadModel.timeReadID}}</span>
                    </td>
                    <td fxFlex="5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{confirmedTimeReadModel.time | date:'HH:mm:ss'}}</span>
                    </td>
                    <td fxFlex="4rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{confirmedTimeReadModel.readerId}}</span>
                    </td>
                    <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{confirmedTimeReadModel.antennaId}}</span>
                    </td>
                    <td fxFlex="4rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{confirmedTimeReadModel.status}}</span>
                    </td>
                    <td fxFlex="4rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{confirmedTimeReadModel.ladIdx}}</span>
                    </td>
                    <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
                  </tr>
                </ng-template>
              </p-table>
              <p-table fxFlex="40" [value]="member.intermediateTimeModel" dataKey="SvemoId"
                       [scrollable]="true" scrollHeight="200px">
                <ng-template pTemplate="header">
                  <div fxLayoutAlign="center center" class="mw_sub_table_header" style="border-bottom:ridge; height:2rem">
                    <span><u><b>Mellantider</b></u></span>
                  </div>
                  <div fxLayoutAlign="center center" class="mw_sub_table_header" style="margin-bottom:0.25rem; font-size:0.7rem">
                    <th fxFlex="60" fxLayoutAlign="center center">Mellantid</th>
                    <th fxFlex="40" fxLayoutAlign="center center">Tid</th>
                    <th fxFlex="2.5rem" fxLayoutAlign="center center">ID1</th>
                    <th fxFlex="2.5rem" fxLayoutAlign="center center">ID2</th>
                    <th fxFlex></th>
                  </div>
                </ng-template>
                <ng-template pTemplate="body" let-intermediateTimeModel let-expanded="expanded">
                  <tr fxFlexLayout="row" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.7rem">
                    <td fxFlex="60" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{displayLapName(intermediateTimeModel.lapID)}}</span>
                    </td>
                    <td fxFlex="40" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{convertToMiniTime(intermediateTimeModel.lapTime)}}</span>
                    </td>
                    <td fxFlex="2.5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{intermediateTimeModel.id1}}</span>
                    </td>
                    <td fxFlex="2.5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                      <span style="font-size:0.8rem">{{intermediateTimeModel.id2}}</span>
                    </td>
                    <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </ng-template>
        </p-table>
      </mat-card>
    </div>
  </mat-tab>

  <!--EFTER ARBETE TÄVLING-->
  <mat-tab label="Städa tider">
    <p-table [value]="displayMembers" dataKey="id" sortMode="multiple"
             [scrollable]="true" scrollHeight="500px">
      <!--HEADER-->
      <ng-template pTemplate="header">
        <div fxLayoutAlign="start center" class="mw_main_table_header">
          <th fxHide.lt-sm style="width: 1.5rem"></th>
          <th fxFlex="2.5rem" fxLayoutAlign="start center" pSortableColumn="startNbr">#<p-sortIcon field="startNbr"></p-sortIcon></th>
          <th fxFlex="12rem" fxLayoutAlign="start center" pSortableColumn="firstName">Namn<p-sortIcon field="firstName"></p-sortIcon></th>
          <th fxFlex="5rem" fxLayoutAlign="start center" pSortableColumn="className">Klass<p-sortIcon field="className"></p-sortIcon></th>
          <th fxFlex="3rem" fxLayoutAlign="center center">Status</th>
          <th fxFlex="4rem" *ngFor="let header of headers" style="font-size:0.9rem">
            <span fxLayoutAlign="center center">{{header.header}}</span>
          </th>
          <th fxFlex></th>
          <th fxFlex="4rem" fxLayoutAlign="center center" style="font-size:0.9rem" pSortableColumn="nbrLaps">Varv<p-sortIcon field="nbrLaps"></p-sortIcon></th>
          <th fxFlex="4rem" fxLayoutAlign="start center" style="font-size:0.9rem" pSortableColumn="totalTime">Tid<p-sortIcon field="totalTime"></p-sortIcon></th>
        </div>
      </ng-template>
      <!--BODY-->
      <ng-template pTemplate="body" let-displayMember let-expanded="expanded">
        <tr fxFlexLayout="row" fxLayoutAlign="center center" fxLayoutGap="0" style="padding: 0px; margin:0px; height:2rem">
          <td fxLayout fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
            <button class="toggleBtn" fxLayout fxLayoutAlign="center center"
                    [pRowToggler]="displayMember">
              <mat-icon *ngIf="!expanded">keyboard_arrow_right</mat-icon>
              <mat-icon *ngIf="expanded">keyboard_arrow_down</mat-icon>
            </button>
          </td>
          <td fxFlex="2.5rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
            <span fxLayoutAlign="start center"><b>{{displayMember.startNbr}}</b></span>
          </td>
          <td fxFlex="12rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
            <span fxLayoutAlign="start center">{{displayMember.firstName + ' ' + displayMember.lastName}}</span>
          </td>
          <td fxFlex="5rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
            <span fxLayoutAlign="start center">{{displayMember.className}}</span>
          </td>
          <td fxFlex="3rem" style="padding: 0rem; margin:0px; height:1.5rem" fxLayoutAlign="center center">
            <mat-icon style="padding: 0rem; margin:0px; font-size:0.8rem" *ngIf="displayMember.confirmedTimeReadModel" fxLayoutAlign="center center"
                      [ngStyle]="{'color':getStatusColor(displayMember)}">circle</mat-icon>
          </td>
          <td *ngFor="let time of displayMember.lapTimeModel" fxFlex="4rem" style="padding: 0rem; margin:0px; height:1.5rem" fxLayoutAlign="center center">
            <div fxFlexLayout="row">
              <span fxLayoutAlign="center center" style="font-size:0.5rem; font-style:italic">{{displayLapName(time.lapID)}} </span>
              <span fxLayoutAlign="center center" style="font-size:0.7rem"> {{convertToMiniTime(time.lapTime)}} </span>
            </div>
          </td>
          <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
          <td fxFlex="4rem" style="padding: 0rem; margin-right:3.5px; height:1.5rem" fxLayoutAlign="center center">
            <span fxLayoutAlign="start center" style="font-size:0.7rem">{{displayMember.nbrLaps}}</span>
          </td>
          <td fxFlex="4rem" style="padding: 0rem; margin-right:3.5px; height:1.5rem" fxLayoutAlign="start center">
            <span fxLayoutAlign="center center" style="font-size:0.7rem"> {{displayMember.totalTime}} </span>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-member>
        <div fxLayout="row" fxLayout.lt-md="column" style="border:double;border-radius:0.5rem; margin:0.5rem;">
          <p-table #dt fxFlex="60" [value]="member.lapTimeModel" dataKey="timeReadID" editMode="row"
                   [scrollable]="true" scrollHeight="300px">
            <ng-template pTemplate="header">
              <div fxLayout="column" fxFlex="70" class="mw_sub_table_header">
                <div fxLayoutAlign="start center" class="mw_sub_table_header" style="height:1.5rem; font-size:1.1rem">
                  <span style="margin-left:2rem"><u><b>Varv tider</b></u></span>
                </div>
                <div fxLayoutAlign="start center" class="mw_sub_table_header" style="margin-bottom:0.25rem; height:3rem; font-size:0.7rem">
                  <th fxFlex="3rem" fxLayoutAlign="center center">ID</th>
                  <th fxFlex="6rem" fxLayoutAlign="center center">Tid</th>
                  <th fxFlex="8rem" fxLayoutAlign="center center">Namn</th>
                  <th fxFlex="4rem" fxLayoutAlign="center center">Varv#</th>
                  <th fxFlex></th>
                </div>
              </div>
              <div fxLayoutAlign="center center" fxFlex="30" class="mw_sub_table_header" style="height:3rem">
                <button class="mw-addBtn-icon" (click)="onAddLap(member)" fxLayoutAlign="start start"><mat-icon class="mw-addIcon-icon">add</mat-icon></button>
              </div>
            </ng-template>

            <ng-template pTemplate="body" let-lapTimeModel let-expanded="expanded" let-editing="editing" let-ri="rowIndex">
              <tr [pEditableRow]="lapTimeModel" fxFlexLayout="row" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:2rem">
                <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                  <span style="font-size:0.8rem">{{lapTimeModel.timeReadID}}</span>
                </td>
                <td fxFlex="6rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="text" [(ngModel)]="lapTimeModel.lapTime" style="font-size:0.8rem; width:5rem; height:1.2rem">
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span fxLayoutAlign="center center" style="font-size:0.8rem"> {{convertToMiniTime(lapTimeModel.lapTime)}} </span>
                    </ng-template>
                  </p-cellEditor>
                </td>

                <td fxFlex="8rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <select [(ngModel)]="lapTimeModel.lapID" style="width:6rem; height:1.2rem; font-size:0.8rem;">
                        <option *ngFor="let lap of selectedEventLaps" value="{{lap.lapId}}">{{lap.lapName}}</option>
                      </select>
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span fxLayoutAlign="center center" style="font-size:0.8rem">{{displayLapName(lapTimeModel.lapID)}} </span>
                    </ng-template>
                  </p-cellEditor>
                </td>

                <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                  <p-cellEditor>
                    <ng-template pTemplate="input">
                      <input pInputText type="number" [(ngModel)]="lapTimeModel.lapNbr" style="font-size:0.8rem; width:2rem; height:1.2rem; align-content:center">
                    </ng-template>
                    <ng-template pTemplate="output">
                      <span style="font-size:0.8rem">{{lapTimeModel.lapNbr}}</span>
                    </ng-template>
                  </p-cellEditor>
                </td>

                <td fxFlex style="padding: 0px; margin:0px; height:1.5rem">
                </td>
                <td fxFlex="4rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
                  <button *ngIf="!editing" class="mw-editBtn-icon_sm" pInitEditableRow (click)="onRowEditInit(lapTimeModel)" matTooltip="Ändra varv">
                    <mat-icon>edit_outline</mat-icon>
                  </button>
                  <button *ngIf="!editing" class="mw-cancelBtn-icon_sm" pInitEditableRow (click)="onRowEditDelete(lapTimeModel, member)" matTooltip="Ta bort varv">
                    <mat-icon>delete</mat-icon>
                  </button>
                  <button *ngIf="editing" class="mw-doneBtn-icon_sm" pSaveEditableRow (click)="onRowEditSave(lapTimeModel, member)" matTooltip="Genomför ändring">
                    <mat-icon>done</mat-icon>
                  </button>
                  <button *ngIf="editing" class="mw-cancelBtn-icon_sm" pCancelEditableRow (click)="onRowEditCancel(member)" matTooltip="Ångra">
                    <mat-icon>undo</mat-icon>
                  </button>
                </td>

                <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
                <!--<td>
                  <button (click)="onConsole(lapTimeModel)">Console</button>
                </td>-->
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div fxLayout="row" fxLayout.lt-md="column" style="border:double;border-radius:0.5rem; margin:0.5rem;">
          <!--ConfirmedTimeReads TABLE-->
          <p-table fxFlex="60" [value]="member.confirmedTimeReadModel" dataKey="SvemoId"
                   [scrollable]="true" scrollHeight="200px">
            <ng-template pTemplate="header">
              <div fxLayoutAlign="center center" class="mw_sub_table_header" style="height:1.5rem; font-size:1.1rem">
                <span><u><b>Tid stämplar</b></u></span>
              </div>
              <div fxLayoutAlign="start center" class="mw_sub_table_header" style="margin-bottom:0.25rem; height:3rem;font-size:0.7rem">
                <th fxFlex="3rem" fxLayoutAlign="center center">ID</th>
                <th fxFlex="5rem" fxLayoutAlign="center center">Tid</th>
                <th fxFlex="4rem" fxLayoutAlign="center center">Läsare</th>
                <th fxFlex="3rem" fxLayoutAlign="center center">Ant</th>
                <th fxFlex="4rem" fxLayoutAlign="center center">Status</th>
                <th fxFlex="4rem" fxLayoutAlign="center center">Varv#</th>
                <th fxFlex></th>
              </div>
            </ng-template>
            <ng-template pTemplate="body" let-confirmedTimeReadModel let-expanded="expanded">
              <tr fxFlexLayout="row" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.7rem"
                  [style.color]="confirmedTimeReadModel.correctRead ? 'black' : 'red' ">
                <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                  <span style="font-size:0.8rem">{{confirmedTimeReadModel.timeReadID}}</span>
                </td>
                <td fxFlex="5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                  <span style="font-size:0.8rem">{{confirmedTimeReadModel.time | date:'HH:mm:ss'}}</span>
                </td>
                <td fxFlex="4rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                  <span style="font-size:0.8rem">{{confirmedTimeReadModel.readerId}}</span>
                </td>
                <td fxFlex="3rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                  <span style="font-size:0.8rem">{{confirmedTimeReadModel.antennaId}}</span>
                </td>
                <td fxFlex="4rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                  <span style="font-size:0.8rem">{{confirmedTimeReadModel.status}}</span>
                </td>
                <td fxFlex="4rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                  <span style="font-size:0.8rem">{{confirmedTimeReadModel.ladIdx}}</span>
                </td>
                <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
              </tr>
            </ng-template>
          </p-table>
          <p-table fxFlex="40" [value]="member.intermediateTimeModel" dataKey="SvemoId"
                   [scrollable]="true" scrollHeight="200px">
            <ng-template pTemplate="header">
              <div fxLayoutAlign="center center" class="mw_sub_table_header" style="height:1.5rem;font-size:1.1rem">
                <span><u><b>Mellantider</b></u></span>
              </div>
              <div fxLayoutAlign="center center" class="mw_sub_table_header" style="margin-bottom:0.25rem; height:3rem;font-size:0.7rem">
                <th fxFlex="60" fxLayoutAlign="center center">Mellantid</th>
                <th fxFlex="40" fxLayoutAlign="center center">Tid</th>
                <th fxFlex="2.5rem" fxLayoutAlign="center center">ID1</th>
                <th fxFlex="2.5rem" fxLayoutAlign="center center">ID2</th>
                <th fxFlex></th>
              </div>
            </ng-template>
            <ng-template pTemplate="body" let-intermediateTimeModel let-expanded="expanded">
              <tr fxFlexLayout="row" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.7rem">
                <td fxFlex="60" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                  <span style="font-size:0.8rem">{{displayLapName(intermediateTimeModel.lapID)}}</span>
                </td>
                <td fxFlex="40" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                  <span style="font-size:0.8rem">{{convertToMiniTime(intermediateTimeModel.lapTime)}}</span>
                </td>
                <td fxFlex="2.5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                  <span style="font-size:0.8rem">{{intermediateTimeModel.id1}}</span>
                </td>
                <td fxFlex="2.5rem" fxLayoutAlign="center center" style="padding: 0px; margin:0px; height:1.5rem">
                  <span style="font-size:0.8rem">{{intermediateTimeModel.id2}}</span>
                </td>
                <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </ng-template>
    </p-table>
  </mat-tab>


  <!--BERÄKNA POÄNG-->
  <mat-tab style="padding:0rem">
    <ng-template mat-tab-label>
      <span fxFlex (click)="computePosition()" style="padding:1rem">Beräkna poäng</span>
    </ng-template>

    <!--<button (click)="computePosition()">Beräkna</button>-->
    <p-table #dtComputePoints [value]="displayMembers" dataKey="id" sortMode="multiple"
             [scrollable]="true" scrollHeight="500px">
      <!--HEADER-->
      <ng-template pTemplate="header">

        <div fxLayoutAlign="start center" class="mw_main_table_header">
          <th fxHide.lt-sm style="width: 1.5rem"></th>
          <th fxFlex="2.5rem" fxLayoutAlign="start center" pSortableColumn="startNbr">#<p-sortIcon field="startNbr"></p-sortIcon></th>
          <th fxFlex="12rem" fxLayoutAlign="start center" pSortableColumn="firstName">Namn<p-sortIcon field="firstName"></p-sortIcon></th>
          <th fxFlex="5rem" fxLayoutAlign="start center" pSortableColumn="className">Namn<p-sortIcon field="className"></p-sortIcon></th>
          <th fxFlex="3rem" fxLayoutAlign="center center">Status</th>
          <th fxFlex="4rem" *ngFor="let header of headers" style="font-size:0.9rem">
            <span fxLayoutAlign="center center">{{header.header}}</span>
          </th>

          <th fxFlex></th>
          <th fxFlex="4rem" fxLayoutAlign="center center" style="font-size:0.9rem" pSortableColumn="nbrLaps">Varv<p-sortIcon field="nbrLaps"></p-sortIcon></th>
          <th fxFlex="4rem" fxLayoutAlign="start center" style="font-size:0.9rem" pSortableColumn="totalTime">Tid<p-sortIcon field="totalTime"></p-sortIcon></th>
          <th fxFlex="6rem" fxLayoutAlign="start center" style="font-size:0.9rem" pSortableColumn="position">Placering<p-sortIcon field="position"></p-sortIcon></th>
          <th fxFlex="6rem" fxLayoutAlign="start center" style="font-size:0.9rem" pSortableColumn="points">Poäng<p-sortIcon field="points"></p-sortIcon></th>
        </div>
      </ng-template>
      <!--BODY-->
      <ng-template pTemplate="body" let-displayMember let-expanded="expanded">
        <tr fxFlexLayout="row" fxLayoutAlign="center center" fxLayoutGap="0" style="padding: 0px; margin:0px; height:2rem">
          <td fxLayout fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
            <button class="toggleBtn" fxLayout fxLayoutAlign="center center"
                    [pRowToggler]="displayMember">
              <mat-icon *ngIf="!expanded">keyboard_arrow_right</mat-icon>
              <mat-icon *ngIf="expanded">keyboard_arrow_down</mat-icon>
            </button>
          </td>
          <td fxFlex="2.5rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
            <span fxLayoutAlign="start center"><b>{{displayMember.startNbr}}</b></span>
          </td>
          <td fxFlex="12rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
            <span fxLayoutAlign="start center">{{displayMember.firstName + ' ' + displayMember.lastName}}</span>
          </td>
          <td fxFlex="5rem" fxLayoutAlign="start center" style="padding: 0px; margin:0px; height:1.5rem">
            <span fxLayoutAlign="start center">{{displayMember.className}}</span>
          </td>
          <td fxFlex="3rem" style="padding: 0rem; margin:0px; height:1.5rem" fxLayoutAlign="center center">
            <mat-icon style="padding: 0rem; margin:0px; font-size:0.8rem" *ngIf="displayMember.confirmedTimeReadModel" fxLayoutAlign="center center"
                      [ngStyle]="{'color':getStatusColor(displayMember)}">circle</mat-icon>
          </td>
          <td *ngFor="let time of displayMember.lapTimeModel" fxFlex="4rem" style="padding: 0rem; margin:0px; height:1.5rem" fxLayoutAlign="center center">
            <div fxFlexLayout="row">
              <span fxLayoutAlign="center center" style="font-size:0.5rem; font-style:italic">{{displayLapName(time.lapID)}} </span>
              <span fxLayoutAlign="center center" style="font-size:0.7rem"> {{convertToMiniTime(time.lapTime)}} </span>
            </div>
          </td>
          <td fxFlex style="padding: 0px; margin:0px; height:1.5rem"></td>
          <td fxFlex="4rem" style="padding: 0rem; margin-right:3.5px; height:1.5rem" fxLayoutAlign="center center">
            <span fxLayoutAlign="start center" style="font-size:0.7rem">{{displayMember.nbrLaps}}</span>
          </td>
          <td fxFlex="4rem" style="padding: 0rem; margin-right:3.5px; height:1.5rem" fxLayoutAlign="center center">
            <span fxLayoutAlign="center center" style="font-size:0.7rem"> {{displayMember.totalTime}} </span>
          </td>
          <td fxFlex="6rem" style="padding: 0rem; margin-right:3.5px; height:1.5rem" fxLayoutAlign="center center">
            <span fxLayoutAlign="center center" style="font-size:0.7rem"><b>{{displayMember.position}}</b> </span>
          </td>
          <td fxFlex="6rem" style="padding: 0rem; margin-right:3.5px; height:1.5rem" fxLayoutAlign="center center">
            <span fxLayoutAlign="center center" style="font-size:0.7rem"><b>{{displayMember.points}}</b> </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div fxLayout="row" fxFlex>
      <div fxLayout="row" fxFlex="3rem">
        <button class="mw-createPDFBtn-icon" (click)="exportPdf()" fxLayoutAlign="start start" matTooltip="Skapa en PDF fil">
          <mat-icon class="mw-createPDFIcon-icon">picture_as_pdf</mat-icon>
        </button>
        <button class="mw-createPDFBtn-icon" (click)="exportPdf()" fxLayoutAlign="start start" matTooltip="Skapa en PDF fil">
          <mat-icon class="mw-createPDFIcon-icon">picture_as_xls</mat-icon>
        </button>
      </div>
      <div fxLayout="row" fxFlex fxLayoutAlign="center center">
        <button class="mw-addDBBtn-icon" (click)="writeToDB()" fxLayoutAlign="center start"
                matTooltip="Skriv resultaten till DB">
          <div fxLayout="row" fxLayoutGap="1rem">
            <span style="font-size:1.5rem" fxLayoutAlign="start center">Skriv till DB</span>
            <mat-icon class="mw-addDBIcon-icon">library_add</mat-icon>
          </div>
        </button>
      </div>
      <div fxLayout="row" fxFlex="3rem">

      </div>
    </div>
</mat-tab>
</mat-tab-group>
