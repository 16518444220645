import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { IClass, IClub, IConfirmedTimeRead, IEvent, IEventMember, IMember, IMemberInCompetition } from '../models/interfaceModels';
import { DisplayMember } from '../pages/run-competition/run-competition.component';
import { DbService } from './db.service';

@Injectable({
  providedIn: 'root'
})
export class DataRoomService {

  constructor(
    private dbService: DbService,
    private sanitizer: DomSanitizer,) {

    dbService.broadCastClubs.subscribe(data => {
      this.clubs = data;
    });
  }

  public clubs: IClub[];

  public showLapName(LapId: number) {
    var laps = this.dbService.allLaps
  }

  public showClubName(clubId: number) {
    var clubs = this.dbService.allClubs;
    if (clubs) {
      var club = clubs.find(data => data.clubModelId == clubId);
      if (club)
        return club.name
      else
        return 'Okänd'
    }
    else
      return 'Okänd' 
  }
  public showMemberName(svemoId: number) {
    var members = this.dbService.allMembers;
    if (members) {
      var member = members.find(data => data.svemoId == svemoId);
      if (member)
        return member.firstName + ' ' + member.lastName
      else
        return 'Okänd'
    }
    else
      return 'Okänd'
  }
  public showMemberNameMini(svemoId: number) {
    var members = this.dbService.allMembers;
    if (members) {
      var member = members.find(data => data.svemoId == svemoId);
      if (member)
        return member.firstName[0] + ' ' + member.lastName
      else
        return 'Okänd'
    }
    else
      return 'Okänd'
  }

  public showClubPic(clubId: number) {
    if (this.dbService.allClubs) {      
      var clubpic = this.dbService.allClubs.find(data => data.clubModelId == clubId).picture;
      if (clubpic) {
        let objectURL = 'data:image/jpeg;base64,' + clubpic;
        var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        return Url;
      }
      else
        return '/assets/img/profile.png'
    }
    else
      return '/assets/img/profile.png'
  }

  public showBikeBrandImage(bikeBrandId: number) {
    var bikeBrands = this.dbService.allBikeBrands;
    if (bikeBrands) {
      var bikePic = bikeBrands.find(data => data.id == bikeBrandId);
      if (bikePic) {
        let objectURL = 'data:image/jpeg;base64,' + bikePic.picture;
        var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        return Url;
      }
      else
        return '/assets/img/baby.png'
    }
    else
      return '/assets/img/baby.png'
  }

  public showBikeBrand(bikeBrandId: number) {
    var bikeBrands = this.dbService.allBikeBrands;
    if (bikeBrands) {
      var bikeBrand = bikeBrands.find(bike => bike.id == bikeBrandId)
      if (bikeBrand)
        return bikeBrand.bikeBrand;
      else
        return 'ej känt'
    }
    else
      return 'ej känt'
  }

  public showImage(picture: any) {
  
    if (picture) {
      let objectURL = 'data:image/jpeg;base64,' + picture;
      var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
      return Url;
    }
    else
      return '/assets/img/profile.png'
  }

  public showRacerPicture(svemoId: number) {
    var members = this.dbService.allMembers;
    if (members) {
      var memberPic = members.find(data => data.svemoId == svemoId);
      if (memberPic.picture) {
        let objectURL = 'data:image/jpeg;base64,' + memberPic.picture;
        var Url = this.sanitizer.bypassSecurityTrustUrl(objectURL);
        return Url;
      }
      else
        return '/assets/img/profile.png'
    }
    else
      return '/assets/img/profile.png'
  }

  public createEventMembers(originalEvent: IEvent) : IEventMember {
    var newEventMember: IEventMember = {
      eventId: originalEvent.eventId,
      eventDate: originalEvent.eventDate, 
      eventName: originalEvent.eventName,
      club: originalEvent.club,
      clubModelId: originalEvent.clubModelId,
      eventType: originalEvent.eventType,
      eventTypeId: originalEvent.eventTypeId,
      hasStarted: originalEvent.hasStarted,
      startType: originalEvent.startType,
      startTypeId: originalEvent.startTypeId,
      weatherData: originalEvent.weatherData,
      temperature: originalEvent.temperature,
      lapinEvent: originalEvent.lapinEvent,
      memberInEvent: originalEvent.memberInEvent,
      memberInCompetition: originalEvent.memberInCompetition,
      timeReadModel: originalEvent.timeReadModel,
      confirmedTimeReadModel: originalEvent.confirmedTimeReadModel,
      intermediateTimeModel: originalEvent.intermediateTimeModel,
      lapTimeModel: originalEvent.lapTimeModel,
      seriesModel: originalEvent.seriesModel,
      seriesModelId: originalEvent.seriesModelId,
      ignoreFirstStartPost:originalEvent.ignoreFirstStartPost,
      lapRepetitions:originalEvent.lapRepetitions,
      members: null,
      classesInEvent: originalEvent.classesInEvent,
    }
    return newEventMember;
  }

  public convertToMiniTime(timeString: string): string {
    
    if (timeString.charAt(8) == ".") {
      var noMilliSec = timeString.split(".").slice(0.1);
      var noHour = noMilliSec[0].split(":");
      var hour = noHour[0];
      var minutes = noHour[1];
      var seconds = noHour[2];
      var milliSecond = noMilliSec[1]
    }
    else {
      var splitTime = timeString.split(":").slice();
      var hour = splitTime[0];
      var minutes = splitTime[1];
      var seconds = splitTime[2];
      var milliSecond = splitTime[3]
    }
    return minutes + ":" + seconds + "." + milliSecond.charAt(0);
  }

  public getLapsToRun(classesInEvent: IClass[], className: string) : number {
    var classModel = classesInEvent.find(data => data.className == className);
    return classModel.nbrLaps;
  }
  public getHeatsToRun(classesInEvent: IClass[], className: string): number {
    var classModel = classesInEvent.find(data => data.className == className);
    return classModel.lapsInHeat;
  }

  // Display of data

  public createMemberInCompetition(displayMember: DisplayMember) {
    var memberInCompetition: IMemberInCompetition = {
      id: displayMember.id,
      eventId: displayMember.eventId,
      svemoId: displayMember.svemoId,
      className: displayMember.className,
      startNbr: displayMember.startNbr,
      isChecked: displayMember.isChecked,
      isPresent: displayMember.isPresent,
      isFinished: displayMember.isFinished,
      totalTime: displayMember.totalTime,
      position: displayMember.position,
      points: displayMember.points,
    }
    if (memberInCompetition.points == null)
      memberInCompetition.points = 0;
    if (memberInCompetition.position == null)
      memberInCompetition.position = 0;

    return memberInCompetition;
  }

  public createDisplayMembers(memberInComp: IMemberInCompetition[]): DisplayMember[] {
    var displayMembers: DisplayMember[] = [];

    memberInComp.forEach(member => {
      var displayMember: DisplayMember = {
        svemoId: member.svemoId,
        isActive: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).isActive,
        firstName: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).firstName,
        lastName: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).lastName,
        clubModel: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).clubModel,
        clubModelId: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).clubModelId,
        clubMembership: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).clubMembership,
        bikeBrandModel: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).bikeBrandModel,
        bikeBrandModelId: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).bikeBrandModelId,
        pictureUrl: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).pictureUrl,
        picture: this.dbService.allMembers.find(data => data.svemoId == member.svemoId).picture,
        timeReadModel: [],
        confirmedTimeReadModel: [],
        intermediateTimeModel: [],
        lapTimeModel: [],
        id: member.id,
        eventId: member.eventId,
        className: member.className,
        startNbr: member.startNbr,
        isChecked: member.isChecked,
        isPresent: member.isPresent,
        isFinished: member.isFinished,
        totalTime: member.totalTime,
        position: member.position,
        nbrLaps: 0,
        points: member.points,
      }
      displayMembers.push(displayMember);
    })
    return displayMembers
  }
  public filterDisplayMembers(displayMember: DisplayMember[], className: string): DisplayMember[] {
    if (displayMember)
      return displayMember.filter(dispMember => dispMember.className == className)
    else
      return null
  }
  //Computes total time from strings and returns a string
  public totalTime(member: DisplayMember) {
    var hours: number = 0;
    var minutes: number = 0;
    var seconds: number = 0;

    member.lapTimeModel.forEach((data) => {
      hours = hours + +data.lapTime.substring(0, 2);
      minutes = minutes + +data.lapTime.substring(3, 5);
      seconds = seconds + +data.lapTime.substring(6, 8);
    })
    minutes = minutes + Math.floor(seconds / 60);
    hours = hours + Math.floor(minutes / 60);
    var finalSeconds = seconds % 60;
    var finalMinutes = minutes % 60;
    var finalHours = hours;
    member.totalTime = finalHours.toString().padStart(2, "0") + ':' + finalMinutes.toString().padStart(2, "0") + ':' + finalSeconds.toString().padStart(2, "0");
    return member;
  }

  //Gets unique classes from a displayMember list
  //returns string array with classNames
  public uniqueClasses(displayMembers:DisplayMember[]) {
    var classes: string[] = [];
    displayMembers.forEach(member => {
      var exists = classes.find(cla => cla == member.className)
      if (!exists) {
        classes.push(member.className)
      }
    })
   // console.log(classes);
    return classes;
  }


  //Validators
  public validateConfirmPassword = (passwordControl: AbstractControl): ValidatorFn => {
    return (confirmationControl: AbstractControl): { [key: string]: boolean } | null => {

      const confirmValue = confirmationControl.value;
      const passwordValue = passwordControl.value;

      if (confirmValue === '') {
        return null;
      }

      if (confirmValue !== passwordValue) {
        return { mustMatch: true }
      }

      return null;
    };
  }

}
